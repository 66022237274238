$font-family: 'Roboto', 'Montserrat', sans-serif;
$font-family-tinyall: 'Montserrat', 'Roboto', sans-serif;
$toolbar-height: 56px;
$horizontal-menu-height: $toolbar-height;
$sidenav-width: 250px;
$sidenav-user-block-height: 111px;
$mini-sidenav-user-block-height: 70px;
$flex-distance: 8px;
$inner-sidenav-content-padding: $flex-distance;
//Login, Register, Errors box
$box-height: 186px;
$box-position: 34px;
$register-box-height: 300px;
$selected-list-color: #eaeaea;
$background-color: #fafafa;

$primary: #5D6293;
$accent: #29A6A4;
$important: #F59C00;
$block: #e44343;

$primary-light: #99A6D5;
$primary-soft: #D3DAED;

$accent-light: #80CAD2;
$accent-soft: #B2DEE7;

$remove: #da0c0c;
$cancel: #f44336;
$selected: #40c545;


//social colors
$facebook: #3c5a9a;
$facebook2: #0074ef;
$twitter: #5da9de;
$linkedin: #006699;
$instagram: #d70046;
$snapchat: #fffd00;
$pinterest: #cb2229;
$reddit: #ff4500;
$whatsapp: #25d366;
$tumblr: #001935;
$telegram: #30a7df;
$youtube: #ff0000;
$medium: #12100e;
$skype: #00afee;
$line: #59d038;
$gmail: #f14336;
$email: #f79925;
$sms: #8ac13f;
