@import url( 'https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url( 'https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
@import url( 'https://fonts.googleapis.com/icon?family=Material+Icons');
@import url( 'https://cdn.quilljs.com/1.2.2/quill.snow.css');
@import url( 'https://cdn.quilljs.com/1.2.2/quill.bubble.css');
@import "app/theme/styles/base";
@import "app/theme/styles/theme";
@import "app/theme/styles/libs-override";
@import "app/theme/styles/rtl";
@import "app/theme/styles/colors";
@import "app/theme/styles/utilities";
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }
  
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
}  
