@import '~@angular/material/theming';

@import 'node_modules/@mat-datetimepicker/core/datetimepicker/datetimepicker-theme.scss';
@import '~saturn-datepicker/theming';
@import 'theme-reset';

$custom-typography: mat-typography-config(
  $font-family: 'Roboto, Montserrat, sans-serif',
);

@include mat-core($custom-typography);

.app {
    &.indigo-light {
        @import "skins/indigo-light";
        @include angular-material-theme($indigo-light-theme);
        @include mat-datetimepicker-theme($indigo-light-theme);
        @include mat-datepicker-theme($indigo-light-theme);
        @include sat-datepicker-theme($indigo-light-theme);
        @include theme-reset($indigo-light-theme);
    }
    &.teal-light {
        @import "skins/teal-light";
        @include angular-material-theme($teal-light-theme);
        @include mat-datetimepicker-theme($teal-light-theme);
        @include mat-datepicker-theme($teal-light-theme);
        @include sat-datepicker-theme($teal-light-theme);
        @include theme-reset($teal-light-theme);
    }
    &.red-light {
        @import "skins/red-light";
        @include angular-material-theme($red-light-theme);
        @include mat-datetimepicker-theme($red-light-theme);
        @include mat-datepicker-theme($red-light-theme);
        @include sat-datepicker-theme($red-light-theme);
        @include theme-reset($red-light-theme);
    }
    &.blue-dark {
        @import "skins/blue-dark";
        @include angular-material-theme($blue-dark-theme);
        @include mat-datetimepicker-theme($blue-dark-theme);
        @include mat-datepicker-theme($blue-dark-theme);
        @include sat-datepicker-theme($blue-dark-theme);
        @include theme-reset($blue-dark-theme);
    }
    &.green-dark {
        @import "skins/green-dark";
        @include angular-material-theme($green-dark-theme);
        @include mat-datetimepicker-theme($green-dark-theme);
        @include mat-datepicker-theme($green-dark-theme);
        @include sat-datepicker-theme($green-dark-theme);
        @include theme-reset($green-dark-theme);
    }
    &.pink-dark {
        @import "skins/pink-dark";
        @include angular-material-theme($pink-dark-theme);
        @include mat-datetimepicker-theme($pink-dark-theme);
        @include mat-datepicker-theme($pink-dark-theme);
        @include sat-datepicker-theme($pink-dark-theme);
        @include theme-reset($pink-dark-theme);
    }
    &.tinyall-light {
        @import "skins/tinyall-light";
        @include angular-material-theme($tinyall-light-theme);
        @include mat-datetimepicker-theme($tinyall-light-theme);
        @include mat-datepicker-theme($tinyall-light-theme);
        @include sat-datepicker-theme($tinyall-light-theme);
        @include theme-reset($tinyall-light-theme);
    }
}